<template>
    <div class="col-12 vh-100">
        <div class="row">
            <div class="d-flex align-items-end flex-column vh-100 p-0 conversation">
                <div class="chatbot-header-outer-padding">
                    <div class="chatbot-header-inner-bg">
                        <div class="whatsapp-header">
                            <h5 class="modal-title" id="staticBackdropLabel">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <img v-if="this.whatsappprofile != null && this.whatsappprofile != ''"
                                            :src="this.whatsappprofile" class="profile-header-avatar rounded-circle"
                                            alt="" width="46" height="46" />
                                        <img v-else src="/assets/images/goa-police-logo.png"
                                            class="profile-header-avatar rounded-circle" alt="" width="46"
                                            height="46" />
                                    </div>
                                    <div class="flex-grow-1" style="margin-left: 15px;">
                                        <div class="whatsapp-header-name text-capitalize"><img
                                                src="/assets/images/goa-police-name.png" alt="goa-police-label"
                                                height="17" /><img
                                                src="/assets/images/whatsapp-icon/material-symbols_verified-rounded.png"
                                                class="ms-1" alt="" width="17" height="17"
                                                style="vertical-align: top;" /></div>
                                        <div class="whatsapp-header-number">online</div>
                                    </div>
                                </div>
                            </h5>
                        </div>

                    </div>
                </div>
                <div class="chatbox-right-outer">
                    <!-- <div class="chat-alert-message-header-fixed">
                        <div class="whatsapp-header-content">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img class="alrt-msg-icon" src="/assets/images/whatsapp-icon/mdi_information.png"
                                        alt="alert" />
                                </div>
                                <div class="flex-grow-1">
                                    <div class="whatsapp-header-content-label">This business uses a secure service to
                                        manage this chat. Click to learn more.</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="chatbox-inner-content" id="chatcontainer">
                        <div class="p-dialog-content">
                            <div class="conversation-container" v-if="!showloadermodal">
                                <div v-if="this.chatstatus == 1">
                                    <div class="message received">
                                        <div class="message-text-received message-received-padding">
                                            <div>
                                                <span v-html="replacebrake(this.complatedmsg)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.chatstatus == 0">
                                    <div v-for="(value, key ) in whatsAppChatHistory" :key="key"
                                        style="display: inline-block; width: 100%;">
                                        <div class="text-center chatbot-date-header">
                                            <div class="date-outer-header">
                                                <span class="date-inner-header"><span class="date-label">{{
                                            format_date(key) }}</span></span>
                                            </div>
                                        </div>
                                        <!-- incoming msg -->
                                        <div v-for="msg in value" :key="msg" class="message-outer-body">
                                            <div v-if="msg.lo3 == 1"
                                                style="width: 100%; display: inline-block; position: relative;">
                                                <div class="message sent">
                                                    <div class="message-send-padding">
                                                        <div v-if="msg.replyTo == 0">
                                                            <div v-if="msg.lo16" class="mb-3">
                                                                <img :src="msg.lo16"
                                                                    style="vertical-align: middle; width: 100%;"
                                                                    class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                            </div>
                                                            <div v-if="msg.lo4" class="message-text-send">
                                                                <span v-html="replacebrake(msg.lo4)"></span>
                                                            </div>
                                                        </div>
                                                        <div v-if="msg.replyTo == 1">
                                                            <!-- <div class="messagereplyouter">
                                                                <div class="text-capitalize" style="color: #850f93bf;">
                                                                    {{ this.whatsappname }}</div>
                                                                <i class="pi pi-image me-1"
                                                                    style="font-size: 12px;"></i><span
                                                                    v-html="replacebrake(msg.last_message.slice(0, 65))"></span><span
                                                                    v-if="msg.last_message.length >= 65"> ...</span>
                                                            </div> -->
                                                            <div v-if="msg.lo4" class="message-text-send">
                                                                <span v-html="replacebrake(msg.lo4)"></span>
                                                            </div>
                                                        </div>
                                                        <a class="whatsapp-image-cursor" v-if="msg.lo6"><img
                                                                :src="msg.lo6" style="vertical-align: middle"
                                                                class="whatsapp-Sent-image p-mt-1" width="300" /></a>
                                                    </div>
                                                </div>
                                                <div class="metadata-send"><span class="time">{{
                                            format_timestamptime(msg.lo2) }}</span></div>
                                            </div>
                                            <!-- Outgoing Msg -->
                                            <div v-if="msg.lo3 == 2">
                                                <div class="message received">
                                                    <div class="">
                                                        <div v-if="msg.lo16" class="">
                                                            <img :src="msg.lo16"
                                                                style="vertical-align: middle; width: 100%;border-radius: 0px 9.41px 0px 0px;"
                                                                class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                                                        </div>
                                                        <div v-if="msg.lo4"
                                                            class="message-text-received message-received-padding">
                                                            <span v-html="replacebrake(msg.lo4)"></span>
                                                        </div>
                                                        <div v-if="msg.lo29 == 12 && msg.lo28 != 1">
                                                            <div class="message-received-padding pb-0">
                                                                <div class="custom-form-group">
                                                                    <Multiselect v-model="countryname"
                                                                        :options="countrynameList" :searchable="true"
                                                                        label="label" placeholder="Select Country"
                                                                        class="multiselect-custom text-capitalize"
                                                                        :canClear="false" :closeOnSelect="true"
                                                                        :object="true">
                                                                        <template v-slot:option="{ option }">
                                                                            <img class="character-option-icon me-2"
                                                                                :src="option.flag" width="18"
                                                                                alt="country-flag">{{ option.label }}
                                                                        </template>
                                                                    </Multiselect>
                                                                </div>
                                                            </div>
                                                            <button type="button"
                                                                class="whatsappbtnreply btn-border-radius-custom"
                                                                :disabled="this.countryname == ''"
                                                                @click="sendCountryName(msg.lo1)">Submit</button>
                                                        </div>
                                                        <div v-if="msg.lo29 == 13 && msg.lo28 != 1">
                                                            <div class="message-received-padding pb-0">
                                                                <div class="custom-form-group">
                                                                    <Calendar v-model="birthdate" :showIcon="true"
                                                                        class="custom-ultima-calendar custom-chatbot-calender"
                                                                        placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                                                        :manualInput="false" :monthNavigator="true"
                                                                        :yearRange="'1950:' + maxDateBirth.getFullYear()"
                                                                        appendTo="body" :yearNavigator="true">
                                                                    </Calendar>
                                                                </div>
                                                            </div>
                                                            <button type="button"
                                                                class="whatsappbtnreply btn-border-radius-custom"
                                                                :disabled="this.birthdate == null"
                                                                @click="sendBirthdate(msg.lo1)">Submit</button>
                                                        </div>
                                                        <div v-if="msg.lo29 == 15 && msg.lo28 != 1">
                                                            <div class="message-received-padding py-0" :class="this.reasontype == 3 ? 'pb-2' : ''">
                                                                <div class="custom-form-group">
                                                                    <div class="form-check custom-chat-radio-form">
                                                                        <input type="radio" class="form-check-input"
                                                                            v-model="reasontype" name="visitor-type-option"
                                                                            id="Leisuretrip" autocomplete="off" value="1" />
                                                                        <label
                                                                            class="form-check-label text-capitalize"
                                                                            for="Leisuretrip">Leisure Trip</label>
                                                                    </div>
                                                                    <div class="form-check custom-chat-radio-form">
                                                                        <input type="radio" class="form-check-input"
                                                                            v-model="reasontype" name="visitor-type-option"
                                                                            id="Businesstrip" autocomplete="off"
                                                                            value="2" />
                                                                        <label
                                                                            class="form-check-label text-capitalize"
                                                                            for="Businesstrip">Business Trip</label>
                                                                    </div>
                                                                    <div class="form-check custom-chat-radio-form">
                                                                        <input type="radio" class="form-check-input"
                                                                            v-model="reasontype" name="visitor-type-option"
                                                                            id="friendsandfamily" autocomplete="off"
                                                                            value="3" />
                                                                        <label
                                                                            class="form-check-label text-capitalize"
                                                                            for="friendsandfamily">Visiting friends & family</label>
                                                                    </div>
                                                                    <div class="form-check custom-chat-radio-form">
                                                                        <input type="radio" class="form-check-input"
                                                                            v-model="reasontype" name="visitor-type-option"
                                                                            id="job" autocomplete="off"
                                                                            value="4" />
                                                                        <label
                                                                            class="form-check-label text-capitalize"
                                                                            for="job">Visiting for job</label>
                                                                    </div>
                                                                    <div class="form-check custom-chat-radio-form">
                                                                        <input type="radio" class="form-check-input"
                                                                            v-model="reasontype" name="visitor-type-option"
                                                                            id="othertrip" autocomplete="off"
                                                                            value="5" />
                                                                        <label
                                                                            class="form-check-label text-capitalize"
                                                                            for="othertrip">Other</label>
                                                                    </div>
                                                                </div>
                                                                <div class="custom-form-group" v-if="this.reasontype == 5">
                                                                    <input type="text" v-model="reasonvalue" class="form-control"
                                            placeholder="Enter Reason" maxlength="50" autocomplete="off" />
                                                                </div>
                                                            </div>
                                                            <button type="button"
                                                                class="whatsappbtnreply btn-border-radius-custom"
                                                                :disabled="this.reasonloader || this.reasontype == '' || (this.reasontype == 5 && this.reasonvalue == '')"
                                                                @click="sendReasonType(msg.lo1)">Submit</button>
                                                        </div>
                                                        <a class="whatsapp-image-cursor" v-if="msg.lo6"><img
                                                                :src="msg.lo6" style="vertical-align: middle"
                                                                class="whatsapp-Sent-image p-mt-1" /></a>
                                                    </div>
                                                    <div>
                                                        <button type="button" class="whatsappbtnreply" v-if="msg.lo17"
                                                            @click="SubmitBtnValue(msg.lo17, msg.lo1)"
                                                            :disabled="msg.lo28 == 1 || this.btnclickloader"
                                                            :class="[msg.last_clicked_btn == msg.lo17 ? 'orange-btn-disabled-color' : '', msg.lo18 == '' || msg.lo18 == null ? 'btn-border-radius-custom' : '']">
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-if="msg.lo28 == 1 || this.btnclickloader" class="me-2"
                                                                style="vertical-align: sub;" width="16.65"
                                                                height="16.65" />
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-else class="me-2" style="vertical-align: sub;"
                                                                width="16.65" height="16.65" />
                                                            {{ msg.lo17
                                                            }}</button>
                                                        <button type="button" class="whatsappbtnreply" v-if="msg.lo18"
                                                            @click="SubmitBtnValue(msg.lo18, msg.lo1)"
                                                            :disabled="msg.lo28 == 1 || this.btnclickloader"
                                                            :class="[msg.last_clicked_btn == msg.lo18 ? 'orange-btn-disabled-color' : '', msg.lo19 == '' || msg.lo19 == null ? 'btn-border-radius-custom' : '']">
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-if="msg.lo28 == 1 || this.btnclickloader" class="me-2"
                                                                style="vertical-align: sub;" width="16.65"
                                                                height="16.65" />
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-else class="me-2" style="vertical-align: sub;"
                                                                width="16.65" height="16.65" />{{ msg.lo18
                                                            }}</button>
                                                        <button type="button" class="whatsappbtnreply" v-if="msg.lo19"
                                                            @click="SubmitBtnValue(msg.lo19, msg.lo1)"
                                                            :disabled="msg.lo28 == 1 || this.btnclickloader"
                                                            :class="[msg.last_clicked_btn == msg.lo19 ? 'orange-btn-disabled-color' : '', msg.lo20 == '' || msg.lo20 == null ? 'btn-border-radius-custom' : '']">
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-if="msg.lo28 == 1 || this.btnclickloader" class="me-2"
                                                                style="vertical-align: sub;" width="16.65"
                                                                height="16.65" />
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-else class="me-2" style="vertical-align: sub;"
                                                                width="16.65" height="16.65" />{{ msg.lo19
                                                            }}</button>
                                                        <button type="button" class="whatsappbtnreply" v-if="msg.lo20"
                                                            @click="SubmitBtnValue(msg.lo20, msg.lo1)"
                                                            :disabled="msg.lo28 == 1 || this.btnclickloader"
                                                            :class="[msg.last_clicked_btn == msg.lo20 ? 'orange-btn-disabled-color' : '', msg.lo21 == '' || msg.lo21 == null ? 'btn-border-radius-custom' : '']">
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-if="msg.lo28 == 1 || this.btnclickloader" class="me-2"
                                                                style="vertical-align: sub;" width="16.65"
                                                                height="16.65" />
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-else class="me-2" style="vertical-align: sub;"
                                                                width="16.65" height="16.65" />{{ msg.lo20
                                                            }}</button>
                                                        <button type="button"
                                                            class="whatsappbtnreply btn-border-radius-custom"
                                                            v-if="msg.lo21" @click="SubmitBtnValue(msg.lo21, msg.lo1)"
                                                            :disabled="msg.lo28 == 1 || this.btnclickloader"
                                                            :class="msg.last_clicked_btn == msg.lo21 ? 'orange-btn-disabled-color' : ''">
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-if="msg.lo28 == 1 || this.btnclickloader" class="me-2"
                                                                style="vertical-align: sub;" width="16.65"
                                                                height="16.65" />
                                                            <img src="/assets/images/whatsapp-icon/send-icon-chatbot.png"
                                                                v-else class="me-2" style="vertical-align: sub;"
                                                                width="16.65" height="16.65" />{{ msg.lo21
                                                            }}</button>
                                                    </div>
                                                </div>
                                                <div class="metadata-received"><span class="time">{{
                                            format_timestamptime(msg.lo2) }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showloadermodal">
                                <div class="custom-modal-spinner-loader">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-0 w-100" style="border-top: 0px;">
                    <div class="whatsapp-textbtn-outer">
                        <div class="custom-chat-picture text-success" v-if="uploadfilename">
                            File attatched
                        </div>
                        <div class="conversation-compose">
                            <div class="input-group">
                                <input type="text" v-on:keyup.enter="sendInputMessage()"
                                    class="form-control input-msg custom-chat-group-textarea"
                                    :disabled="this.input_validation == 2 || this.btnloader || this.input_validation == 12 || this.input_validation == 13 || this.input_validation == 7"
                                    :maxlength="this.input_validation == 4 ? '12' : this.input_validation == 5 ? '6' : this.input_validation == 6 ? '10' : '30'"
                                    :class="this.input_validation == 6 ? 'text-uppercase' : ''" v-model="chatInput"
                                    rows="1" placeholder="Message"
                                    v-on:="this.input_validation == 4 ? ({ keypress: onlyNumber, paste: onPasteAadhar }) : (this.input_validation == 5 ? { keypress: onlyNumber, paste: onPasteOtp } :
                                            (this.input_validation == 3 ? { keypress: onlyNumber, paste: onPasteMobile } : ''))" />
                                <div class="input-group-append" v-if="this.countryflag != 1">
                                    <span class="input-group-text"
                                        :class="this.input_validation != 7 ? 'disabled' : ''">
                                        <label class="d-flex">
                                            <img src="/assets/images/whatsapp-icon/ic_outline-plus.png"
                                                style="vertical-align: sub;" width="24" height="24" />
                                            <input type="file" hidden="" accept="image/*" capture="camera"
                                                :disabled="this.input_validation == 2 || this.btnloader"
                                                name="uploadfile" ref="uploadfile" multiple
                                                v-on:change="validateAttachment" />
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div class="send-btn-outer">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1" style="padding-left: 18px;">
                                        <button class="send" @click="sendInputMessage()"
                                            :disabled="this.input_validation == 2 || this.btnloader || this.input_validation == 12 || this.input_validation == 13">
                                            <img src="/assets/images/whatsapp-icon/majesticons_share-line.png"
                                                class="send-icon-btn" width="24" height="24" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import ApiService from "../service/ApiService";
import { useRoute } from "vue-router";
export default {
    data() {
        return {
            whatsappprofile: '',
            whatsappname: "Goa Police",
            whatsAppChatHistory: [],
            showloadermodal: false,
            file1: null,
            uploadfilename: null,
            chatInput: "",
            routeParam: '',
            input_validation: '',
            btnloader: false,
            btnclickloader: false,
            chatstatus: '',
            complatedmsg: "You are already Registered as a Valuable Guest in Goa State!<br><br>Have a wonderful Stay and Vacation in Goa!",
            countrynameList: [],
            countryname: "",
            countrybtnloader: false,
            maxDateBirth: new Date(),
            birthdate: null,
            reasontype: '',
            reasonvalue: '',
            reasonloader: false,
            countryflag : '',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    beforeUnmount() {
        clearInterval(this.timer3);
    },
    mounted() {
        const route = useRoute();
        var routeParamquery = route.query.value;
        this.routeParam = routeParamquery;
        // this.timer3 = window.setInterval(() => {
        //     this.getVisitorWebBotHistoryreload();
        // }, 2000);
        this.getVisitorWebBotHistory();
        this.getCountryListBot();
    },
    methods: {
        getVisitorWebBotHistory() {
            this.showloadermodal = true;
            this.chatInput = "";
            this.file1 = "";
            this.uploadfilename = "";
            this.ApiService.getVisitorWebBotHistory({ aa1: this.routeParam }).then((data) => {
                if (data.success == true) {
                    this.showloadermodal = false;
                    this.whatsAppChatHistory = data.data;
                    this.input_validation = data.input_validation;
                    this.countryflag = data.country;
                    this.scrollmessage();
                    this.chatstatus = data.webBotStatus;
                } else {
                    this.input_validation = 0;
                    this.countryflag = 0;
                    this.showloadermodal = false;
                    this.whatsAppChatHistory = null;
                    this.scrollmessage();
                }
            });
        },
        sendInputMessage() {
            this.btnloader = true;
            const formData = new FormData();
            formData.append("lo1", "");
            if (this.input_validation == 7) {
                formData.append("MediaType", "image");
            } else {
                formData.append("MediaType", "text");
            }
            formData.append("visitorID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", "");
            if (this.chatInput) {
                if (this.input_validation == 6) {
                    formData.append("Text", this.chatInput.toUpperCase());
                } else {
                    formData.append("Text", this.chatInput);
                }
            }
            if (this.file1) {
                formData.append("MediaData", this.file1);
            }
            if (this.chatInput != '' || this.file1 != null) {
                this.ApiService.webincomingmsg(formData).then(() => {
                    this.chatInput = "";
                    this.file1 = "";
                    this.uploadfilename = "";
                    this.input_validation = '';
                    this.getVisitorWebBotHistorysubmit();

                }
                );
            } else {
                this.btnloader = false;
            }
        },
        SubmitBtnValue(label, lo1) {
            this.btnclickloader = true;
            const formData = new FormData();
            formData.append("lo1", lo1);
            formData.append("MediaType", "button");
            formData.append("MediaData", "");
            formData.append("Text", "");
            formData.append("visitorID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", label);
            this.ApiService.webincomingmsg(formData).then(() => {
                this.chatInput = "";
                this.file1 = "";
                this.uploadfilename = "";
                this.input_validation = '';
                this.getVisitorWebBotHistorysubmit();
            });
        },
        sendCountryName(lo1) {
            const formData = new FormData();
            formData.append("lo1", lo1);
            formData.append("MediaType", "text");
            formData.append("MediaData", "");
            formData.append("Text", this.countryname.label);
            formData.append("visitorID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", "");
            formData.append("country_fk", this.countryname.value);
            formData.append("country_fk_value", this.countryname.label);
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ' - ' + pair[1]);
            // }
            this.ApiService.webincomingmsg(formData).then(() => {
                this.chatInput = "";
                this.file1 = "";
                this.uploadfilename = "";
                this.input_validation = '';
                this.countryname = '';
                this.getVisitorWebBotHistorysubmit();
            });
        },
        sendReasonType(lo1) {
            this.reasonloader = true;
            const formData = new FormData();
            formData.append("lo1", lo1);
            formData.append("MediaType", "text");
            formData.append("MediaData", "");
            if(this.reasontype == 1) {
                formData.append("Text", 'Leisure Trip');
            } else if(this.reasontype == 2) {
                formData.append("Text", 'Business Trip');
            } else if(this.reasontype == 3) {
                formData.append("Text", 'Visiting friends & family');
            } else if(this.reasontype == 4) {
                formData.append("Text", 'Visiting for job');
            } else if(this.reasontype == 5) {
                formData.append("Text", this.reasonvalue);
            }
            
            formData.append("visitorID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", "");
            formData.append("reason_fk", this.reasontype);
            if(this.reasontype == 1) {
                formData.append("reason_fk_value", 'Leisure Trip');
            } else if(this.reasontype == 2) {
                formData.append("reason_fk_value", 'Business Trip');
            } else if(this.reasontype == 3) {
                formData.append("reason_fk_value", 'Visiting friends & family');
            } else if(this.reasontype == 4) {
                formData.append("reason_fk_value", 'Visiting for job');
            } else if(this.reasontype == 5) {
                formData.append("reason_fk_value", this.reasonvalue);
            }
            this.ApiService.webincomingmsg(formData).then(() => {
                this.chatInput = "";
                this.file1 = "";
                this.uploadfilename = "";
                this.input_validation = '';
                this.countryname = '';
                this.reasonvalue = '';
                this.reasontype = '';
                this.getVisitorWebBotHistorysubmit();
                this.reasonloader = false;
            });
        },
        sendBirthdate(lo1) {
            const formData = new FormData();
            formData.append("lo1", lo1);
            formData.append("MediaType", "text");
            formData.append("MediaData", "");
            formData.append("Text", moment(this.birthdate).format("YYYY-MM-DD"));
            formData.append("visitorID", this.routeParam);
            formData.append("CONTEXT_MESSAGE_ID", "");
            formData.append("ButtonLabel", "");
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ' - ' + pair[1]);
            // }
            this.ApiService.webincomingmsg(formData).then(() => {
                this.chatInput = "";
                this.file1 = "";
                this.uploadfilename = "";
                this.input_validation = '';
                this.birthdate = null;
                this.getVisitorWebBotHistorysubmit();
            });
        },
        getVisitorWebBotHistoryreload() {
            this.ApiService.getVisitorWebBotHistory({ aa1: this.routeParam }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.data;
                    this.input_validation = data.input_validation;
                } else {
                    this.whatsAppChatHistory = null;
                }
            });
        },
        getVisitorWebBotHistorysubmit() {
            this.ApiService.getVisitorWebBotHistory({ aa1: this.routeParam }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.data;
                    this.input_validation = data.input_validation;
                    this.scrollmessage();
                    this.btnloader = false;
                    this.btnclickloader = false;
                } else {
                    this.whatsAppChatHistory = null;
                    this.btnloader = false;
                    this.btnclickloader = false;
                }
            });
        },
        validateAttachment(e) {
            var fileData = e.target.files[0];
            this.uploadfilename = fileData.name;
            if (this.$refs.uploadfile.files.length > 2) {
                this.$toast.open({
                    message: "Maximum 2 files are allowed",
                    type: "error",
                    duration: 3000,
                    position: "top-right",
                });
            } else {
                let len = this.$refs.uploadfile.files.length;
                if (len === 1) {
                    this.file1 = this.$refs.uploadfile.files[0];
                } else if (len === 2) {
                    this.file1 = this.$refs.uploadfile.files[0];
                    this.file2 = this.$refs.uploadfile.files[1];
                }
            }
        },

        getCountryListBot(e) {
            this.ApiService.getCountryListBot(e).then((data) => {
                if (data.status == 200) {
                    this.countrynameList = data.data;
                } else {
                    this.countrynameList = null;
                }
            });
        },
        format_timestamptime(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("hh:mm a");
            } else {
                return 'N/A';
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD MMMM YYYY");
            }
        },
        scrollmessage() {
            setTimeout(() => {
                var messageBody = document.querySelector('#chatcontainer');
                if (messageBody != null) {
                    messageBody.scrollTop = messageBody.scrollHeight;
                }
            }, 1000);
        },
        replacebrake(html) {
            if (html) {
                const doc = html;
                return doc.replace(/(\r)*\n/g, '<br>');
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        isLetterWithSpaceNumber($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z0-9\s]+$/.test(char)) return true;
            else $event.preventDefault();
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(mobvalue)) {
                let value = mobvalue.replace(/\s/g, '');
                this.chatInput = value.slice(0, 10);
                return true;
            }
            else event.preventDefault();
        },
        onPasteOtp(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(otpvalue)) {
                let value = otpvalue.replace(/\s/g, '');
                this.chatInput = value.slice(0, 4);
                return true;
            }
            else event.preventDefault();
        },
        onPasteAadhar(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (/^[0-9 ]+$/.test(otpvalue)) {
                let value = otpvalue.replace(/\s/g, '');
                this.chatInput = value.slice(0, 12);
                return true;
            }
            else event.preventDefault();
        },
        onPasteCommon(event) {
            let otpvalue = event.clipboardData.getData('text');
            if (otpvalue) {
                this.chatInput = otpvalue.slice(0, 30);
                return true;
            }
            else event.preventDefault();
        },
    },
}
</script>
<style scoped>
/* new chatbot css start here */
.chatbot-header-outer-padding {
    font-family: 'Inter-Regular';
    background-color: #ffffff;
    width: 100%;
}

.whatsapp-header {
    padding: 13px 20.5px;
    gap: 70px;
    border-radius: 12px;
    box-shadow: 0px 1px 5.6px 0px #00000026;
    background: #FFFFFF;
    height: 72px;
}

.whatsapp-header-name {
    font-family: 'Inter-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: #000000;
    margin-bottom: 6px;
    height: 21px;
    padding-top: 4px;
}

.whatsapp-header-number {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #C4C4D5;
    height: 15px;
}

.chatbot-header-inner-bg {
    padding: 21px 15px 19px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}

.whatsapp-header-content {
    background: #F1F2F5;
    padding: 6.02px 8.69px 6.02px 8.69px;
    gap: 4.68px;
    border-radius: 7.35px;
    opacity: 0px;
}

.whatsapp-header-content .whatsapp-header-content-label {
    font-family: 'Inter-Regular';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    /* text-align: center; */
    color: #8D93A5;
}

/* .message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    position: relative;
    margin: 8px 0;
    max-width: 20%;
    word-wrap: break-word;
} */
.message.received {
    background: #175971;
    opacity: 0.80;
    border-radius: 0px 10.52px 10.52px 10.52px;
}


.message .message-received-padding {
    padding: 13.14px 17.29px 27.17px 17.77px;
}

.message .message-send-padding {
    padding: 17.19px 16px 16.81px 12px;
}

.message .message-text-received {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;

}

.message .message-text-send {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;

}



.whatsappbtnreply {
    width: 100%;
    border: 0;
    /* margin-bottom: 6px; */
    background-color: transparent;
    font-family: 'Inter-Regular';
    border-radius: 0;
    padding: 14.2px 16.65px;
    border-top: 0.88px solid rgba(214, 216, 216, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;
    color: #FFFFFF;
}

.whatsappbtnreply:disabled {
    background: #ced4da;
}

.orange-btn-disabled-color {
    background-color: #F3A02A !important;
}

.metadata-received .time {
    font-family: 'Inter-Regular';
    font-size: 10.52px;
    font-weight: 400;
    line-height: 12.73px;
    text-align: left;
    color: #667781;

}

.metadata-received {
    margin-top: 9.64px;
    line-height: 12.73px;
}

.metadata-send {
    margin-top: 8.54px;
    float: right;
    width: 100%;
    text-align: right;
}

.metadata-send .time {
    font-family: 'Inter-Regular';
    font-size: 10.52px;
    font-weight: 400;
    line-height: 12.73px;
    text-align: right;
    color: #667781;
}

.message-outer-body {
    display: inline-block;
    width: 100%;
    clear: both;
    position: relative;
    margin-bottom: 22px;
}

.alrt-msg-icon {
    width: 20.3px;
    height: 20.3px;
    margin: 4px;
}

.conversation {
    height: calc(100% - 12px);
    position: relative;
    background: url("/assets/images/whatsapp-icon/bg-chatbot.png") no-repeat;
    z-index: 0;
    background-size: contain;
}

.whatsapp-textbtn-outer {
    padding: 20.5px 19.5px;
    width: 100%;
    margin: 0;
}

.conversation-compose .send {
    background: #F3A02A;
    border-radius: 20px;
    position: relative;
    width: 66px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 8px 21px;
}

.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 40px;
    width: 100%;
    z-index: 2;
}

.form-control.input-msg.custom-chat-group-textarea {
    text-align: left;
    color: #3D3D3D;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    padding: 12.5px 13px;
    resize: none;
    border-radius: 21px;
    font-weight: 500;
    line-height: 14.52px;
    border: 1px solid #DBDEE7;
    height: 40px;
    padding-right: 0;
    border-right: 0;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 12px;
    font-family: 'Inter-Regular';
    text-align: start;
    text-transform: capitalize;
    line-height: 14.52px;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
    box-shadow: none;
}

.conversation-compose .input-group-text {
    padding: 7px;
    border: 1px solid #DBDEE7;
    background-color: #ffffff;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.chatbot-date-header {
    margin-bottom: 35px;
}

.chatbot-date-header .date-outer-header {
    text-align: center;
    position: relative;
    border-bottom: 1px solid rgb(240, 240, 246);
}

.chatbot-date-header .date-inner-header {
    padding: 0px 15px;
    position: relative;
    top: 11px;
    background: #fff;
}

.chatbot-date-header .date-label {
    color: #8D93A5;
    font-family: 'Inter-Regular';
    font-size: 12px;
    line-height: 14.52px;
    padding: 6.79px 18.1px;
    border-radius: 12.06px;
    background: #F1F2F5;
    font-weight: 400;
    position: relative;
}

.conversation-container {
    height: calc(100% - 5px);
    padding: 0 25px;
}

.message.sent {
    background: #F0F0F6;
    border-radius: 9.32px 9.32px 0px 9.32px;
    float: right;
    width: auto;
}

.form-control.input-msg.custom-chat-group-textarea:disabled {
    background: #f7f7f7;
    border: 0;
}

.input-group .input-group-text.disabled {
    background: #f7f7f7;
    border-color: #f7f7f7;
}

.input-group .input-group-text.disabled img {
    opacity: 0.2;
}

.form-control.input-msg.custom-chat-group-textarea:disabled::placeholder {
    color: #fff;
}

.conversation-compose .send:disabled {
    background: #f7f7f7;
}

.messagereplyouter {
    background: #e1f1d2;
    padding: 8px;
    margin-bottom: 5px;
    box-shadow: -3px 0px 0px 0px rgb(72 73 161 / 75%);
    border-radius: 3px;
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #070829;

}

.chat-alert-message-header-fixed {
    padding: 21px 15px;
    background: linear-gradient(179.44deg, #FFFFFF 29.31%, rgba(255, 255, 255, 0.01) 89.07%);
}

/* #old css start here */

.chatbox-right-outer {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    padding: 0;
    height: 100%;
    overflow: auto;
}

.chatbox-right-outer .chatbox-inner-content {
    height: calc(100% - 0px);
    /* box-shadow: inset 0 10px 10px -10px #000000; */
    padding: 2px 0;
    overflow: auto;
    position: relative;
}

.p-dialog-content {
    padding: 0;
}

.whatsapp-header .modal-title {
    color: #ffffff;
}

.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
    transition: all 0.5s !important;
    width: 3px !important;
    height: 3px !important;
    z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
    background: #c54d4d !important;
    /* background: #7f7777 !important; */
}

.whatsapp-image-cursor {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
    opacity: 0.5;
    display: block;
}

.conversation-compose .send-btn-outer {
    background: transparent;
    border: 0;
    flex: 0 0 auto;
    padding: 0;
    position: relative;
    outline: none;
}


/* .conversation-compose .send:focus {
    opacity: 0.8;
} */

.conversation-compose .input-msg:hover {
    background-image: none;
}

.conversation-compose .input-msg:focus {
    background-color: #ffffff;
}

.whatsapp-imger-overlay:hover {
    opacity: 0.7;
}

.btn-border-radius-custom {
    border-radius: 0px 0px 10.52px 10.52px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

    .message.received,
    .message.sent,
    .metadata-received {
        max-width: 40%;
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    .message.received,
    .message.sent,
    .metadata-received {
        max-width: 60%;
    }
}

@media (min-width: 320px) and (max-width: 1024px) {

    .message.received,
    .message.sent,
    .metadata-received,
    .metadata-send {
        max-width: 86%;
    }
}

.custom-form-group .form-control,
.custom-form-group .multiselect-dropdown {
    font-family: 'Inter-Regular';
    font-weight: 400;
}

::v-deep .custom-chatbot-calender .p-button {
    background: #F3A02A;
    border: 1px solid #F3A02A;
    border-radius: 0 4px 4px 0;
}
.custom-chat-radio-form {
    min-height: 2.5rem;
    align-items: center;
    display: flex;
}
.custom-chat-radio-form .form-check-input {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 10px;
}
.custom-chat-radio-form .form-check-label {
    font-family: 'Inter-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;
}
</style>